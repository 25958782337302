<template>
  <v-card>
    <v-card-text>
      <v-container fluid>
        <v-row>
          <v-col cols="4">
            <v-card class="mx-2 my-2 card" elevation="2" outlined>
              <v-card-text class="text-center">
                <div class="subtitle-1 font-weight-bold">Total Vehicles</div>
                <div class="body-1 green--text">{{ data.vehicles }}</div>
                <v-icon color="green" small>mdi-truck</v-icon>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-card class="mx-2 my-2 card" elevation="2" outlined>
              <v-card-text class="text-center">
                <div class="subtitle-1 font-weight-bold">Total Drivers</div>
                <div class="body-1 green--text">{{ data.drivers }}</div>
                <v-icon color="green" small>mdi-account-hard-hat</v-icon>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-card class="mx-2 my-2 card" elevation="2" outlined>
              <v-card-text class="text-center">
                <div class="subtitle-1 font-weight-bold">Total Routes</div>
                <div class="body-1 green--text">{{ data.routes }}</div>
                <v-icon color="green" small>mdi-map-marker-path</v-icon>
              </v-card-text>
            </v-card>
          </v-col>
          <div v-if="data.Documents"></div>
          <v-col v-for="(item, index) in data.Documents" :key="index" cols="4">
            <v-card class="mx-2 my-2 card" elevation="2" outlined>
              <v-card-text class="text-center">
                <div class="subtitle-1 font-weight-bold">
                  {{ item.Document }}
                </div>

                <div class="d-flex justify-center align-center mt-2">
                  <div class="body-1 orange--text mr-2">
                    Open: {{ item.Open.Documents }}
                    <v-icon color="orange" small>mdi-folder-open</v-icon>
                  </div>
                  <div class="d-flex flex-column align-center">
                    <div class="body-1 orange--text mb-1">
                      Drivers {{ item.Open.drivers }}
                      <v-icon color="orange" small>mdi-account-hard-hat</v-icon>
                    </div>
                    <div class="body-1 orange--text">
                      Vehicles {{ item.Open.vehicles }}
                      <v-icon color="orange" small>mdi-truck</v-icon>
                    </div>
                  </div>
                </div>

                <div class="d-flex justify-center align-center mt-2">
                  <div class="body-1 green--text mr-2">
                    Closed: {{ item.Closed.Documents }}
                    <v-icon color="green" small>mdi-check-circle</v-icon>
                  </div>
                  <div class="d-flex flex-column align-center">
                    <div class="body-1 green--text mb-1">
                      Drivers {{ item.Closed.drivers }}
                      <v-icon color="green" small>mdi-account-hard-hat</v-icon>
                    </div>
                    <div class="body-1 green--text">
                      Vehicles {{ item.Closed.vehicles }}
                      <v-icon color="green" small>mdi-truck</v-icon>
                    </div>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
.card {
  transition: transform 0.3s;
}

.card:hover {
  transform: scale(1.05);
}
.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.d-flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mb-1 {
  margin-bottom: 0.25rem;
}
</style>
