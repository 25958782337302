<template>
  <v-card>
    <v-container fluid>
      <v-card-title>
        <v-row dense>
          <v-col cols="12" sm="4" md="3">
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="options.startDate"
                  label="Start Date"
                  readonly
                  v-on="on"
                  dense
                  :rules="[(v) => !!v || 'Start Date is required']"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="options.startDate"
                @input="updateRange"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="4" md="3">
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-text-field
                  dense
                  v-model="options.endDate"
                  label="End Date"
                  readonly
                  v-on="on"
                  :rules="[(v) => !!v || 'End Date is required']"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="options.endDate"
                @input="updateRange"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="4" md="3">
            <v-autocomplete
              v-model="options.drivers"
              :items="drivers"
              dense
              attach
              chips
              small-chips
              clearable
              deletable-chips
              label="Select Driver"
              item-text="RlpName"
              item-value="RlpCode"
              multiple
              :rules="[(v) => v.length > 0 || 'At least one driver is required']"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="2">
            <v-btn
              small
              :loading="searchLoader"
              color="primary"
              @click="getOpenItems"
              class="mt-4"
            >
              Apply Filters <v-icon right>mdi-card-search</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <dashboard-card title="Documents" :data="documentData" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-container>
  </v-card>
</template>
<script>
import DashboardCard from "../_components/dashboardCard.vue";
export default {
  components: {
    DashboardCard,
  },
  data() {
    return {
      documentData: [],
      searchLoader: false,
      loader: false,
      dialog: false,
      drivers: [],
      selectedData: null,
      groupByEnabled: false,
      updateRange: null,
      search: null,
      options: {
        startDate: null,
        endDate: null,
        DocStatus: "O",
        search: null,
        drivers: [],
        vehicle_id: [],
        SlpCode: [],
      },
    };
  },
  methods: {
    defaultDates() {
      if (!this.options.startDate) {
        const today = new Date();
        this.options.startDate = today.toISOString().substr(0, 10);
      }

      if (!this.options.endDate) {
        const today = new Date();
        this.options.endDate = today.toISOString().substr(0, 10);
      }
    },
    getOpenItems() {
      const self = this;
      let url = `/dispatch/summary-reports`;
      if (this.options.startDate) {
        url += `${url.includes("?") ? "&" : "?"}StartDate=${this.options.startDate}`;
      }
      if (this.options.endDate) {
        url += `${url.includes("?") ? "&" : "?"}EndDate=${this.options.endDate}`;
      }
      if (this.options.search) {
        url += `${url.includes("?") ? "&" : "?"}search=${this.options.search}`;
      }
      if (Object.keys(this.options.drivers).length > 0) {
        url += `${url.includes("?") ? "&" : "?"}RlpCode=${this.options.drivers}`;
      }
      if (Object.keys(this.options.vehicle_id).length > 0) {
        url += `${url.includes("?") ? "&" : "?"}vehicle_id=${this.options.vehicle_id}`;
      }
      if (Object.keys(this.options.SlpCode).length > 0) {
        url += `${url.includes("?") ? "&" : "?"}SlpCode=${this.options.SlpCode}`;
      }
      this.loading = true;
      this.$store
        .dispatch("get", url)
        .then((res) => {
          self.documentData = res.ResponseData;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          //   this.$refs.snackbar.show(err, "red");
          this.loading = false;
        });
    },
    getDrivers() {
      const self = this;
      this.$store
        .dispatch("get", `/drivers`)
        .then((res) => {
          self.drivers = res.ResponseData;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getOpenItems();
    this.defaultDates();
    this.getDrivers();
  },
};
</script>
